import { React, useEffect, useState } from 'react';
import { CBadge, CCard, CCardBody, CSpinner, CImage } from '@coreui/react';
import HealthCheck from '../services/HealthCheckService.mjs';
import HealthStatus from '../components/HealthStatus';
import Logo from '../images/targipsum_logo_green.png'

export default function Home()
{
  const [synchroInterface, setSynchroInterface] = useState(null);
  const [backgroundServices, setBackgroundServices] = useState(null);
  const [replicationServices, setReplicationServices] = useState(null);

  useEffect(() =>
  {    
    HealthCheck("https://targipsumsynchro.com").then((response) => {
      setSynchroInterface(response);
    });
    HealthCheck("https://ssyzo2atd5l6fmvzw27elk4xku0kqxzu.lambda-url.us-east-2.on.aws/").then((response) => {
      setBackgroundServices(response);
    });
    
    HealthCheck("https://crsla4srbai773kx64lgixpwym0keyzf.lambda-url.ca-central-1.on.aws/").then((response) => {
      setReplicationServices(response);
    });
  }, []);

  return(
    <div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
          <CImage className='mt-5' src={Logo} width={300} />
          <h3 className='m-5'>Targipsum Synchro Health Dashboard</h3>
    </div>
      <div style={{width:"60%"}} className='mx-auto d-flex flex-row justify-content-between align-items-center'>
        <CCard className='p-4 shadow' style={{width:"30%"}}>
          <a href='https://targipsumsynchro.com'>
          <HealthStatus Title={"Synchro Services Website"} Status={synchroInterface}/>
          </a>
        </CCard>
        <CCard className='p-4 shadow' style={{width:"30%"}}>
          <HealthStatus Title={"Background Services"} Status={backgroundServices} />
        </CCard>
        <CCard className='p-4 shadow' style={{width:"30%"}}>
         <HealthStatus Title={"Replication Background Services"} Status={replicationServices}/>
        </CCard>
      </div>
    </div>
    )
}