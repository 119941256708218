import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExecuteLogin from '../services/OmnitracsService.mjs';
import { CButton, CForm, CFormInput, CImage } from '@coreui/react';
import Logo from '../images/targipsum_logo_green.png'
import '../components/styles/login.css'

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigator = useNavigate();

  const handleLogin = async() => {
    setErrorMessage('');
    try
    {
      await ExecuteLogin(username, password).then((response) => {
        if(response.error != null)
        {
          setErrorMessage(response.error);
        }
        else
        {
          let expirationDate = new Date(Date.now());
          expirationDate.setTime(expirationDate.getTime() + 1000 * 60 * 60 * 24 * 7);
          console.log(expirationDate.toISOString());
          const tokenSuccess = {
            token: response,
            expiry: expirationDate
          }
          localStorage.setItem("token", JSON.stringify(tokenSuccess));
          navigator('/logs');
        }
      });
    }
    catch(error)
    {
      console.log(error.message);
      setErrorMessage('An error occurred while trying to login.');
    };  
  }

  return (
    <div style={{width:"100vw", height:"100vh"}} className='d-flex flex-column justify-content-center align-items-center login-background'>
      <div className='p-5 rounded-4 shadow-lg w-50 bg-white'>
        <CImage className='mb-5' src={Logo} width={200} />
        <CForm>
          <h6 className='text-start'>Email</h6>
          <CFormInput className='mb-3' type='email' placeholder='name@email.com' onChange={(e) => setUsername(e.target.value)}/>
          <h6 className='text-start'>Password</h6>
          <CFormInput type='password' placeholder='password' onChange={(e) => setPassword(e.target.value)}/>
          <h5 className='text-danger mt-3'>{errorMessage}</h5>
          <CButton className='mt-3 w-50' onClick={handleLogin}>Login</CButton>
        </CForm>
      </div>
    </div>
  );
}

export default LoginPage;