import '@coreui/coreui/dist/css/coreui.min.css'
import React, { useState } from 'react';
import { CSidebar, CSidebarBrand, CSidebarNav, CNavTitle, CNavItem, CSidebarToggler, CBadge, CContainer } from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { cilSpeedometer } from '@coreui/icons'

export default function Sidebar()
{
  return (
      <CSidebar hidden position='sticky' placement='end'>
        <CSidebarBrand>Sidebar Brand</CSidebarBrand>
        <CSidebarNav>
          <CNavTitle>Nav Title</CNavTitle>
          <CNavItem href="#">
            <CIcon customClassName="nav-icon" icon={cilSpeedometer} />
            Nav item
          </CNavItem>
          <CNavItem href="#">
            <CIcon customClassName="nav-icon" icon={cilSpeedometer} />
            With badge
            <CBadge color="primary ms-auto">NEW</CBadge>
          </CNavItem>
        </CSidebarNav>
      </CSidebar>      
  )
}