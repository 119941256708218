import axios from 'axios';

const HealthCheck = async(url) =>
{
  const start = performance.now();
    await axios.get(url).then((response) => {
      if(response?.ok) return true;
      else return false;
  }).catch((error) => {
    console.log(error)
  });  
  const end = performance.now();  
  if(end - start > 10000) return false;  
  else return true;
}

export default HealthCheck;