import { CloudWatchLogsClient, StartQueryCommand, GetQueryResultsCommand } from "@aws-sdk/client-cloudwatch-logs"; // ES Modules import

const client = new CloudWatchLogsClient({
  region: "us-east-2",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_MAIN,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_MAIN
  }
});

const GetInsightsQuery = async() => {

  const input = {
    logGroupName: "synchro/transfer",
    queryString: "fields @timestamp, @message, @logStream | filter @message like /.*(?i)error.*/ or  @message like /.*(?i)Reason.*/ or @message like /.*(?i)Raison.*/ | sort @timestamp desc | limit 1000",
    startTime: Date.now() - 60*60*72*1000, // 72hours back
    endTime: Date.now() - 1000*60*5 // 5minutes back
  }

  console.log(`StartDate: ${new Date(input.startTime)} EndDate: ${new Date(input.endTime)}`)
  const command = new StartQueryCommand(input);  
  const response = await client.send(command);
  console.log(response);
  const queryId = response.queryId;

  const getQueryResultsCommand = new GetQueryResultsCommand({ queryId: queryId });
  let results = await client.send(getQueryResultsCommand);
  while(results.status == 'Running')
  {
    console.log('Query is still running');
    results = await client.send(getQueryResultsCommand);
    await new Promise(r => setTimeout(r, 1000));
  }
  if(results.status == 'Complete')
  {
    return results?.results;
  }  
}

export { GetInsightsQuery }