import logo from './logo.svg';
import './App.css';
import Logs from '../src/pages/Logs.js';
import LoginPage from '../src/pages/Login.js';
import Home from '../src/pages/Home.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/logs" Component={Logs} />
        <Route path="/login" Component={LoginPage} />

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
