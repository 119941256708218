import axios from 'axios';

const {
  NODE_ENV
} = process.env

const API_URL = NODE_ENV === 'development'
  ? "https://fxcjnaz4gd.execute-api.us-east-2.amazonaws.com/dev/user-session"
  : "https://f01d1g8ta3.execute-api.us-east-2.amazonaws.com/prod/user-session"

const ExecuteLogin = async(user, password) =>
{
  try{
    let company = user.split('@')[1].split('.')[0];
    if(company == 'targipsum')
    {
      const response = await axios.post(API_URL, {
        email: user,
        password: password,
        sandbox: false
      }, {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      });
      return response.data.id; 
    }
    return { 
      error: "You must be a Targipsum user to login."
    }
  }
  catch(error)
  {
    console.log(error)
    //error.response.data.accountLocked
    if(error.response.data?.accountLocked == true)
    {
      return {
        error: "Your account is locked."
      }
    }
    else if(error.response.status == 401 || error.response.status == 400)
    {
      return {
        error: "Invalid credentials."
      }
    }
    else
    {
      return {
        error: "An error occurred while trying to login."
      }
    }
  }
}

export default ExecuteLogin;