import { cilXCircle, cilCheckCircle } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CSpinner } from "@coreui/react";



export default function HealthStatus(props) {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className={props.Status ? "d-flex bg-success" : "d-flex bg-secondary"} style={{width:"100px", height:"100px", borderRadius:"50%"}}>
        <div className="d-flex" style={{backgroundColor:"whitesmoke", width:"80%", height:"80%", borderRadius:"50%",margin:"auto"}}>
          { props.Status == null ? <CSpinner className="m-auto" color="primary" /> 
          :
          <CIcon className={props.Status ? "m-auto text-success" : "m-auto text-danger"} width={100} icon={props.Status ? cilCheckCircle : cilXCircle} />
          }          
        </div>
      </div>
      <h4 className="mt-3 text-center">{props.Title}</h4>
    </div>
  );
}